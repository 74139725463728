import { useEffect, useState } from 'react';
import LoaderItem from "../../services/LoaderItem";
import PriceFormatter from '../priceFormatter/PriceFormater';
import '../../components/fontawesome-5.5/css/all.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import '../../components/css/tooplate-infinite-loop.css'
import UpdaterCounter from '../../utils/counter';
import config from '../../config';
const domain = config.api_domain.domain
const domainFront = config.api_domain.domainFront

const IteratorServices = ({ num }) => {
  const url = `${domain}/house/list_houses/houses`;
  const [cleanDataDetail, setCleanDataDetail] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const items = await LoaderItem(url);
        const itemsInversment = items.filter(item => {
          return item.item_class === 'Remate';
        });
        setCleanDataDetail(itemsInversment);
      } catch (error) {
        console.error('Error al cargar los datos:', error);
        setCleanDataDetail([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (!cleanDataDetail || cleanDataDetail.length === 0 || cleanDataDetail === ![]) {
    return <p className="text-center">Sin oportunidades de inversión disponibles aún</p>;
  }

  return (
    <div className='row'>
      {cleanDataDetail.slice(0, num).map((item, index) => {
        return (
          <a
            className='col-lg-4'
            key={index}
            href={`${domainFront}/item_service/${item.id}`}
            onClick={(event) => {
              event.stopPropagation();
              UpdaterCounter(item.id, `${item.title}: ${item.city}, ${item.sector}`);
            }}
          >
            <div className='custom-card mt-5'>
              <br />
              <div className="custom-card-body">
                <h3 className="custom-title"><strong>{item.type}</strong></h3>
                <div className="location">
                  <FontAwesomeIcon icon={faMapMarker} /> <strong>{item.city}</strong>, {item.sector}
                </div>
                <hr />
                <div className="custom-description">{item.short_desc}</div>
                <br />
                <div className="custom-price">
                  <div className="price">Avaluo: $<strong><PriceFormatter value={item.price} /></strong></div>
                  <div className="discount" style={{ fontSize: '15px' }}>Postura minima: <PriceFormatter value={item.discount} />%</div>
                  <div className="final-price">$<strong><PriceFormatter value={item.price - (item.price * (item.discount / 100))} /></strong> </div>
                </div>
                <div className="custom-btn">
                  <p>Ver detalle!</p>
                </div>
              </div>
            </div>
          </a>
        );
      })}
    </div>
  )
};


export default IteratorServices;
