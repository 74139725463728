import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import Gallery from './pages/gallery/Gallery';
import Item from './pages/item/Item';
import ItemService from './pages/item/ItemService';
import Login from './pages/login/Login';
import New from './pages/admin/New'
import Update from './pages/admin/Update';
import Control from './pages/admin/Control';
import Comunications from './pages/admin/Comunications';
import Tools from './pages/admin/Tools';
import List from './pages/admin/List';
import Iterator from './components/iterator/Iterator';
import IteratorServices from './components/iterator/IteratorServices';
import Footer from './components/footer/Footer';
import Admin from './pages/admin/Admin';
import ItemImages from './pages/admin/Images';
import Terms from './pages/terms/Terms';
import { useCookies } from 'react-cookie';


function App() {
  const [cookies] = useCookies(['token_auth_sesion']);
  const [admin, setAdmin] = useState(null)

  const handleSecure = async () => {
    try {
      const token = cookies.token_auth_sesion;
      console.log(token)
      if (token === undefined || !token) {
        setAdmin(false)
        return
      }

      else {
        setAdmin(true)
      }

    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    handleSecure()
  }, [])


  return (
    <BrowserRouter>
      <div className='main-screen' >
        <Routes>
          <Route path='/' element={<Home IteratorComponent={Iterator} IteratorServices={IteratorServices} FooterElement={Footer} />}  />
          <Route path='main' element={<Gallery IteratorComponent={Iterator} FooterElement={Footer} />} />
          <Route path='mainServices' element={<Gallery IteratorComponent={IteratorServices} FooterElement={Footer} />} />
          <Route path='item/:id' element={<Item FooterElement={Footer} />}/>
          <Route path='item_service/:id' element={<ItemService FooterElement={Footer} />}/>
          <Route path='terms' element={<Terms FooterElement={Footer} />}/>
          <Route path='login' element={<Login />}/>
          {admin ? (
              <Route path='admin/' element={<Admin/>} >
                <Route path='control' element={<Control/>} />
                <Route path='list' element={<List/>} />
                <Route path='new' element={<New/>} />
                <Route path='update/:id' element={<Update/>} />
                <Route path='images/:id' element={<ItemImages/>} />
                <Route path='comunications' element={<Comunications/>} />
                <Route path='tools' element={<Tools/>} />
              </Route>
          ) : (
            <Route path='admin/*' element={<Login />}/>
          )}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
        