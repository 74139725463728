import React from 'react'
import { useState } from 'react';
import Swal from "sweetalert2";
import PostService from '../../services/PostService';

const ComunicationForm = () => {

    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [tyc, setTyc] = useState(false)

    const [createdDone, setCreatedDone] = useState(false)

    const handleName = (e) => { setName(e.target.value) }
    const handleLastName = (e) => { setLastName(e.target.value) }
    const handleEmail = (e) => { setEmail(e.target.value) }
    const handleMessage = (e) => { setMessage(e.target.value) }
    const handlePhone = (e) => { setPhone(e.target.value) }
    const handleTyc = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setTyc(value);
    };


    const handleSendInfo = async () => {
        try {
            const data = {
                "name": name,
                "last_name": lastName,
                "email": email,
                "phone": phone,
                "message": message,
                "terms": tyc
            }

            await PostService('house/insert_comunication', data)
            setCreatedDone(true)
        } catch (error) {
            console.error(error);
        }
    }

    const handleNew = () => {
        setName('')
        setLastName('')
        setMessage('')
        setPhone('')
        setTyc(false)
        setCreatedDone(false)
    }

    const handleSendData = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Se enviará tu mensaje. ¿Deseas continuar?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#0064C8',
            cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                handleSendInfo();
                console.log('Comunicación enviada')
            }
        });
    }

    return (
        <div>
            {createdDone === false ?
                <div>
                    <div className="row">
                        <div className="col-sm-6">
                            <input id="name" name="name" email="text" placeholder="Nombre" class="tm-input" onChange={handleName} required />
                        </div>
                        <div className="col-sm-6">
                            <input id="last-name" name="last-name" email="text" placeholder="Apellido" class="tm-input" onChange={handleLastName} required />
                        </div>
                        <div className="col-sm-6">
                            <input id="email" name="email" email="email" placeholder="Email" class="tm-input" onChange={handleEmail} required />
                        </div>
                        <div className="col-sm-6">
                            <input id="email" name="phone" email="phone" placeholder="Celular" class="tm-input" onChange={handlePhone} required />
                        </div>
                        <div className="col-sm-12">
                            <textarea id="message" name="message" rows="8" placeholder="Mensaje" class="tm-input" onChange={handleMessage} required></textarea>
                        </div>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" onChange={handleTyc} required />
                        <label class="form-check-label" for="flexCheckChecked">
                            Acepto <a target='__blank' href="/terms">terminos y condiciones</a>
                        </label>
                        <br /><br />
                    </div>
                    <button onClick={handleSendData} class="btn tm-btn-submit">Enviar</button>
                </div>
                :
                <div>
                    <h3>Gracias!!</h3>
                    <br />
                    <p>Tu mensaje sera revisado por uno de nuestros asesores, nos comunicaremos lo mas printo posible</p>
                    <br />
                    <button class="btn tm-btn-submit" onClick={handleNew}>Enviar otro mensaje</button>
                </div>
            }
        </div>
    )
}

export default ComunicationForm