import axios from 'axios';

const LoaderItem = async (url) => {
    try {
        const res = await axios.get(url)
        return (res.data.body)
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default LoaderItem