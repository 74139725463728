import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import LoaderItem from '../../services/LoaderItem';
import '../../components/css/tooplate-infinite-loop.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faMap} from '@fortawesome/free-solid-svg-icons';

import PriceFormatter from '../../components/priceFormatter/PriceFormater';
import Homebar from '../../components/homebar/Homebar';

import config from '../../config';
const domain = config.api_domain.domain

const ItemService = ({ FooterElement }) => {
    const params = useParams()
    const id = params.id
    const url = `${domain}/house/houses/${id}`
    const [cleanDataDetail, setCleanDataDetail] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const item = await LoaderItem(url);
                setCleanDataDetail(item);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, [id, url]);

    return (
        <div>
            <Homebar />
            <br /><br /><br />
            <div className='container'>

                {cleanDataDetail.length > 0 ? (
                    <>
                        <h2 className="tm-text-primary tm-section-title-alt mb-4"><strong>{cleanDataDetail[0].item_class}</strong> {cleanDataDetail[0].title}</h2>
                        <div>{cleanDataDetail[0].type}</div>
                        <p>{cleanDataDetail[0].short_desc}</p>
                        <div><FontAwesomeIcon className='blue-item' icon={faMapMarker} /> <strong>{cleanDataDetail[0].city}</strong>, {cleanDataDetail[0].sector}  {cleanDataDetail[0].floor === null ? <></> : <>- Piso {cleanDataDetail[0].floor}</>}</div>
                        <br />
                        <div><FontAwesomeIcon className='blue-item' icon={faMap} /> <a href={cleanDataDetail[0].maps} target='__blank'>Ver mapa del sector</a></div>
                        <hr /><br />
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="sec-description">
                                    <h2 className="tm-text-primary tm-section-title-alt mb-4">Descripción del bien</h2>
                                    <div>{cleanDataDetail[0].description}</div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <h2 className="tm-text-primary tm-section-title-alt mb-4">Precio</h2>
                                <div className="row">
                                    <div className='col-sm-12'><p>Avalúo comercial: <strong> $<PriceFormatter value={cleanDataDetail[0].price} /></strong></p> </div>

                                    <div className='col-sm-12'><p>Postura minima: <strong><PriceFormatter value={cleanDataDetail[0].discount} />%</strong></p> </div>

                                    <div className="col-sm-12"> <p><strong className='final-price blue-item'>$<PriceFormatter value={cleanDataDetail[0].price * (cleanDataDetail[0].discount / 100)} /></strong></p></div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <strong><a target='blank__' href="https://api.whatsapp.com/send?phone=573007220272&text=Hola!, estoy interesado los servicios!">¡Solicita más información dando clic aquí!</a></strong>
                        <br /><br /><br />
                    </>
                ) : (
                    'Cargando...'
                )}
            </div>
            <FooterElement />
        </div>
    )
}

export default ItemService;
