import axios from 'axios';
import config from '../config';
const domain = config.api_domain.domain

const DeleteService = async (path, token) => {

    try {
        const url = `${domain}/${path}`;
        const res = await axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // Agrega el token como Bearer
            }
        });

        return res.data.body;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default DeleteService