import '../../components/css/tooplate-infinite-loop.css'
import '../../components/scripts/scripts'
import '../../components/fontawesome-5.5/css/all.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faGavel, faHandshake, faHeadset, faSignature, faUserTie } from '@fortawesome/free-solid-svg-icons';
import logo from '../../components/img/logo/logo5.png'

const Home = ({ IteratorComponent, IteratorServices, FooterElement }) => {

  return (
    <div className="">
      <section id="infinite" className="text-white tm-font-big tm-parallax">
        <nav className="navbar navbar-expand-md tm-navbar" id="tmNav">
          <div className="container">
            <div className="tm-next">
              <a href="#infinite" className="navbar-brand">Remateseninmuebles.com</a>
            </div>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i className="fas fa-bars navbar-toggler-icon"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="#infinite">Inicio</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="#whatwedo">Acerca de</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="#how-works">Como funciona?</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="/main">Galeria</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link tm-nav-link" href="#contact">Contactanos</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="text-center tm-hero-text-container">
          <div className="tm-hero-text-container-inner">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <br />
            <p className="tm-hero-subtitle">
              Asesorias en inmuebles
              <br />Abogados
            </p>
          </div>
        </div>

        <div className="tm-next tm-intro-next">
          <a href="#whatwedo" className="text-center tm-down-arrow-link">
            <i className="fas fa-1x fa-arrow-down tm-down-arrow"> Iniciar</i>
          </a>
        </div>
      </section>
      <section id="whatwedo" className="tm-section-pad-top">

        <div className="container">

          <div className="row tm-content-box">
            <div className="col-lg-12 col-xl-12">
              <div className="tm-intro-text-container">
                <h2 className="tm-text-primary mb-4 tm-section-title">Acerca de nosotros</h2>
                <p className="mb-4 tm-intro-text">
                <strong>Remates en inmuebles</strong> nos hace sentir orgullosos al ofrecer un equipo de expertos legales altamente capacitados en el ámbito de bienes raíces y remates judiciales. Nuestra misión es brindar a nuestros clientes asesoramiento legal profesional y personalizado para garantizar transacciones inmobiliarias seguras y eficientes. Ya sea que estés comprando, vendiendo o enfrentando desafíos legales relacionados con propiedades, estamos aquí para proteger tus intereses y lograr soluciones exitosas. Confía en nosotros para navegar por el complejo mundo legal de bienes raíces con la experiencia y la integridad que mereces.
                  <strong><a href="#contact"> Comunicate con nosotros o visita nuestras redes sociales</a></strong> </p>
              </div>
            </div>
          </div>
          <div className="row tm-content-box">
            <div className="col-lg-1">
              <i className="far fa-3x fa-chart-bar text-center tm-icon"></i>
            </div>
            <div className="col-lg-5">
              <div className="tm-intro-text-container">
                <h2 className="tm-text-primary mb-4">Asesoramiento Legal Especializado</h2>
                <p className="mb-4 tm-intro-text">
                  Contamos con abogados altamente calificados para brindarte la orientación legal precisa en tus decisiones más importantes</p>
              </div>
            </div>

            <div className="col-lg-1">
              <i className="far fa-3x fa-comment-alt text-center tm-icon"></i>
            </div>
            <div className="col-lg-5">
              <div className="tm-intro-text-container">
                <h2 className="tm-text-primary mb-4">Acompañamiento Profesional</h2>
                <p className="mb-4 tm-intro-text">
                  Nuestros abogados te guiarán en cada paso del proceso legal, brindándote apoyo y seguridad en tu camino hacia el éxito</p>
              </div>
            </div>

          </div>
          <div className="row tm-content-box">
            <div className="col-lg-1">
              <i className="fas fa-3x fa-fingerprint text-center tm-icon"></i>
            </div>
            <div className="col-lg-5">
              <div className="tm-intro-text-container">
                <h2 className="tm-text-primary mb-4">Investigaciones Legales Precisas</h2>
                <p className="mb-4 tm-intro-text">
                  Realizamos investigaciones exhaustivas para respaldar tus necesidades legales, ofreciendo información confiable y estrategias efectivas</p>
              </div>
            </div>
            <div className="col-lg-1">
              <i className="fas fa-3x fa-users text-center tm-icon"></i>
            </div>
            <div className="col-lg-5">
              <div className="tm-intro-text-container">
                <h2 className="tm-text-primary mb-4">Intervenciones Jurídicas Especializadas</h2>
                <p className="mb-4 tm-intro-text">
                  En situaciones complejas, nuestros expertos legales te representarán con diligencia y conocimiento para alcanzar los mejores resultados</p>
              </div>
            </div>
          </div>
        </div>

      </section>
      <section id="how-works" className="tm-section-pad-top tm-parallax-2">
        <div className="container tm-testimonials-content">
          <div className="row">
            <div className="col-lg-12 tm-content-box">
              <h2 className="text-white text-center mb-4 tm-section-title">¿Como funciona?</h2>
              <p className="mx-auto tm-section-desc text-center">
                Con <strong>Remates en inmuebles</strong> te facilitamos la vida, en estos simples pasos puedes acceder a asesorias especializadas
              </p>
              <div className="mx-auto tm-gallery-container tm-gallery-container-2">
                <div className="row">
                  <div className="body-card col-sm-4 text-center">
                    <div>
                    <div className="sbt-icon">
                        <FontAwesomeIcon scale={'10px'} icon={faHeadset} />
                      </div>
                      <div className="sbt-title">1. Contáctanos</div>
                      <div className="sbt-text">Estamos listos para ayudarte con tus necesidades legales de bienes raíces. Contáctanos y hablemos sobre tu situación. ¡Estamos aquí para ti!</div>
                    </div>
                  </div>

                  <div className="body-card col-sm-4 text-center">
                    <div>
                      <div className="sbt-icon">
                        <FontAwesomeIcon scale={'10px'} icon={faFile} />
                      </div>
                      <div className="sbt-title">2. Aporta información de tu caso</div>
                      <div className="sbt-text">Tu caso es único, y queremos conocerlo. Proporciona detalles sobre tu situación inmobiliaria. La información que nos brindes es vital para ofrecerte la mejor asesoría legal</div>
                    </div>
                  </div>

                  <div className="body-card col-sm-4 text-center">
                    <div>
                      <div className="sbt-icon">
                        <FontAwesomeIcon scale={'10px'} icon={faUserTie} />
                      </div>
                      <div className="sbt-title">3. Recibe tu asesoría</div>
                      <div className="sbt-text">Confía en nuestros especialistas en inmuebles. Recibe una asesoría completa y personalizada. Estamos aquí para resolver tus dudas y guiar tus decisiones</div>
                    </div>
                  </div>

                  <div className="body-card col-sm-4 text-center">
                    <div>
                      <div className="sbt-icon">
                        <FontAwesomeIcon scale={'10px'} icon={faSignature} />
                      </div>
                      <div className="sbt-title">4. Diligencia la documentación</div>
                      <div className="sbt-text">La documentación legal puede ser complicada, pero nosotros te ayudamos. Simplifica tu proceso con nuestra asistencia en la preparación de documentos clave</div>
                    </div>
                  </div>

                  <div className="body-card col-sm-4 text-center">
                    <div>
                      <div className="sbt-icon">
                        <FontAwesomeIcon scale={'10px'} icon={faGavel} />
                      </div>
                      <div className="sbt-title">5. Ejecuta tu proceso</div>
                      <div className="sbt-text">No estás solo en tu proceso legal. Te acompañamos en cada paso, desde el inicio hasta la resolución. Juntos, avanzamos hacia tus metas inmobiliarias</div>
                    </div>
                  </div>

                  <div className="body-card col-sm-4 text-center">
                    <div>
                      <div className="sbt-icon">
                        <FontAwesomeIcon scale={'10px'} icon={faHandshake} />
                      </div>
                      <div className="sbt-title">6. Recibe y gestiona tu inmueble</div>
                      <div className="sbt-text">Cuando llega el momento de recibir tu inmueble, nuestro equipo sigue a tu lado. Gestionamos todo el proceso para que tú te enfoques en disfrutarlo</div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <a href="#whatwedo" className="text-center tm-down-arrow-link">
            <i className="fas tm-down-arrow"> Contáctanos</i>
          </a>
        </div>
      </section>

      <section id="gallery" className="tm-section-pad-top">
        <div className="container tm-container-gallery">
          <div className="row">
            <div className="text-center col-12">
              <h2 className="tm-text-primary tm-section-title mb-4">Portafolio de remates</h2>
              <p className="mx-auto tm-section-desc">
              Explora nuestras oportunidades de inversión en remates judiciales. Estos son seleccionados y preanalizados de forma jurídica, física y económica para optimizar tiempo y mejorar la rentabilidad.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mx-auto tm-gallery-container">
                <IteratorServices num={3} />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <a href="/mainServices" className="text-center tm-down-arrow-link">
            <i className="fas fa-1x tm-down-arrow">Ver todos los remates</i>
          </a>
        </div>
      </section>

      <hr className='container' />

      <section id="gallery" className="tm-section-pad-top">
        <div className="container tm-container-gallery">
          <div className="row">
            <div className="text-center col-12">
              <h2 className="tm-text-primary tm-section-title mb-4">Galería de inmuebles en venta</h2>
              <p className="mx-auto tm-section-desc">
              Encuentra los inmuebles disponibles a la venta con un descuento especial!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mx-auto tm-gallery-container">
                <IteratorComponent num={3} />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <a href="/main" className="text-center tm-down-arrow-link">
            <i className="fas fa-1x tm-down-arrow">Ver Galeria Completa</i>
          </a>
        </div>
      </section>
      <FooterElement/>
    </div>
  )
}

export default Home;