import axios from 'axios';
import config from '../config';
const domain = config.api_domain.domain

const LoginService = async (username, password) => {
    try {
        const url = (`${domain}/login`)
        const res = await axios.post(url, 
            {
                "password": password,
                "username": username
            }
            , {headers: { 'Content-Type': 'application/json' }})
        console.log(res.data.body)        
        return (res.data.body)
        
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default LoginService