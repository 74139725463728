import '../../components/css/tooplate-infinite-loop.css'
import Homebar from '../../components/homebar/Homebar';

const Gallery = ({IteratorComponent, FooterElement}) => {

    return (
        <div>
            <Homebar />
            <section id="gallery" className="tm-section-pad-top">

                <div className="container tm-container-gallery">
                    <div className="row">
                        <div className="text-center col-12">
                            <h2 className="tm-text-primary tm-section-title mb-4">Galería de bienes</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mx-auto tm-gallery-container">
                                <IteratorComponent num={1000} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterElement />
        </div>
    )
}

export default Gallery;