import { useEffect, useState } from 'react';
import '../../components/css/tooplate-infinite-loop.css'
import LoaderItem from "../../services/LoaderItem";
import config from '../../config';

const List = () => {
    const domain = config.api_domain.domain
    const url = `${domain}/house/list/houses`
    const [cleanDataDetail, setCleanDataDetail] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const item = await LoaderItem(url);
                setCleanDataDetail(item);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, [url]);

    if (!cleanDataDetail || cleanDataDetail.length === 0 || cleanDataDetail === ![]) {
        // Cuando no hay elementos disponibles
        return <div>Sin elementos disponibles</div>;
      }
  
    return (
        <div className='container'>
            <p>Lista de inmuebles</p>
            <br />
            <ul>
                {cleanDataDetail.map((item, index) => (
                    <li className='m-3' key={index}>
                        <a href={`/admin/update/${item.id}`}>{item.id}: {item.title}, {item.city} {item.sector} <strong>{item.state === true ? 'Activo' : 'Vendido'}</strong> </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default List;