import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import LoginService from '../../services/LoginService';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [cookies, setCookie] = useCookies(['token']);

    const handleLogin = async () => {
        try {
            const token = await LoginService(username, password);

            if (token) {
                setCookie('token_auth_sesion', token, { path: '/' });
            } else {
                console.log(null);
            }
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
        }
    };

    return (
        <div>
            <h1>Login</h1>
            <label>
                Username:
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
            </label>
            <br />
            <label>
                Password:
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </label>
            <br />
            <button onClick={handleLogin}>Iniciar Sesión</button>
        </div>
    );
};

export default Login;