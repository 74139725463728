import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import LoaderItem from '../../services/LoaderItem';
import PostService from '../../services/PostService';
import '../../components/css/tooplate-infinite-loop.css';
import DeleteService from '../../services/DeleteItem';
import { useCookies } from 'react-cookie';
import config from '../../config';
const domain = config.api_domain.domain

const ItemImages = () => {
    const params = useParams();
    const id = params.id;
    const url = `${domain}/house/houses/${id}`;
    const [cleanDataDetail, setCleanDataDetail] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [newImageUrl, setNewImageUrl] = useState('');
    const [cookies] = useCookies(['token_auth_sesion']);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = cookies.token_auth_sesion;
                const item = await LoaderItem(url, token);
                setCleanDataDetail(item[0]['images']);
                console.log(await cleanDataDetail)
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, [id, url]);

    const handleSaveImage = async () => {
        try {
            let data = {
                item_id: id,
                url: newImageUrl
            }
            await PostService('house/add_image', data);
            window.location.reload();
        } catch (error) {
            console.error('Error al guardar la imagen:', error);
        }
    };


    const handleDeleteImage = async (id_item) => {
        try {
            let data = {}
            await DeleteService(`house/delete_image/${id_item}`, data);
            window.location.reload()
        } catch (error) {
            console.error('Error al eliminar la imagen:', error);
        }
    };

    return (
        <div className='container'>
            <h2 className="tm-text-primary mb-4">Imagenes del inmueble</h2>
            {showForm === false ? <div>
            <button className='btn btn-primary' onClick={() => setShowForm(!showForm)}>Agregar Imagen</button>
            </div> : <></> }
            {showForm && (
                <div>
                    <input
                        type="text"
                        placeholder="Ingrese la URL de la imagen"
                        value={newImageUrl}
                        onChange={(e) => setNewImageUrl(e.target.value)}
                    />
                    <br /><br />
                    <button className='btn btn-primary' onClick={handleSaveImage}>Guardar Imagen</button>
                </div>
            )}
            <br /><br />
            <div className="row">
                {cleanDataDetail.map((item, index) => (
                    <div className='col-sm-4' key={index}>
                        <button className='btn btn-danger' onClick={() => handleDeleteImage(item.id)}>X</button>
                        <img src={item.url} style={{ width: '100%', margin: '5px' }} alt={`Imagen ${item.id}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ItemImages;
