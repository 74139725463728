import axios from 'axios';
import config from '../config';
const domain = config.api_domain.domain

const UpdaterCounter = async (id, item) => {
    try {
        const url = (`${domain}/house/insert_counter`)
        const data = {
          "id_item": id,
          "item": item
        }
        const res = await axios.post(url, data, {headers: { 'Content-Type': 'application/json' }})
        return (res.data.body)
        
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default UpdaterCounter