import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import LoaderItem from '../../services/LoaderItem';
import '../../components/css/tooplate-infinite-loop.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faMapMarker, faBath, faBed, faMap, faRestroom, faRuler, faNetworkWired, faStreetView, faFutbol, faWater, faStop, faSmile, faTree, faUtensils, faDumbbell } from '@fortawesome/free-solid-svg-icons';

import PriceFormatter from '../../components/priceFormatter/PriceFormater';
import Homebar from '../../components/homebar/Homebar';
import ModalImg from '../../components/modals/ModalImg';

import config from '../../config';
const domain = config.api_domain.domain

const Item = ({FooterElement}) => {
    const params = useParams()
    const id = params.id
    const url = `${domain}/house/houses/${id}`
    const [cleanDataDetail, setCleanDataDetail] = useState([])
    const [imagesUrl, setImagesUrl] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const item = await LoaderItem(url);
                setCleanDataDetail(item);
                setImagesUrl(item[0].images)
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, [id, url]);
    
    return (
        <div>
        <Homebar />
        <br /><br /><br />
        <div className='container'>
            
            {cleanDataDetail.length > 0 ? (
                <>
                    <h2 className="tm-text-primary tm-section-title-alt mb-4"><strong>{cleanDataDetail[0].item_class}</strong>, {cleanDataDetail[0].title}</h2>
                    <div>{cleanDataDetail[0].type}</div>
                    <p>{cleanDataDetail[0].short_desc}</p>
                    <div><FontAwesomeIcon className='blue-item' icon={faMapMarker} /> <strong>{cleanDataDetail[0].city}</strong>, {cleanDataDetail[0].sector} - Piso {cleanDataDetail[0].floor}</div>
                    <p>Año de construcción <strong>{cleanDataDetail[0].year}</strong></p>
                    <div><FontAwesomeIcon className='blue-item' icon={faMap} /> <a href={cleanDataDetail[0].maps} target='__blank'>Ver en mapa</a></div>
                    <hr />
                    <div className='sec-item-images'>
                        <h3 className="tm-text-primary tm-section-title-alt mb-4">Imágenes</h3>
                        <div className="father-box">
                            <div className="child-box">
                                <div className="main-image">
                                    <div className="img" style={{ backgroundImage: `url("${imagesUrl[0]['url']}")` }}>
                                    </div>
                                </div>
                            </div>
                            <div className="child-box">
                                <div className="father-box">
                                    <div className="child-sm-box aux-img">
                                        <div className="img" style={{ backgroundImage: `url("${imagesUrl[1]['url']}")`}}></div>
                                    </div>
                                    <div className="child-sm-box aux-img">
                                        <div className="img" style={{ backgroundImage: `url("${imagesUrl[2]['url']}")`}}></div>
                                    </div>
                                    <div className="child-sm-box aux-img">
                                        <div className="img" style={{ backgroundImage: `url("${imagesUrl[3]['url']}")`}}></div>
                                    </div>
                                    <div className="child-sm-box aux-img">
                                        <div className="img" style={{ backgroundImage: `url("${imagesUrl[4]['url']}")`}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <ModalImg imgData={imagesUrl} />
                    </div>
                    <hr />
                    <div className="item-features">
                        <div className="row">
                            <div className="col-sm-6">
                                <h2 className="tm-text-primary tm-section-title-alt mb-4">Carácteristicas</h2>
                                <div className="row">
                                    <div className='col-sm-4'><p> <FontAwesomeIcon className='blue-item' icon={faRuler} /> mt2: {cleanDataDetail[0].mt2}</p></div>
                                    <div className='col-sm-4'><p><FontAwesomeIcon className='blue-item' icon={faBed} /> Habitaciones: {cleanDataDetail[0].rooms}</p></div>
                                    <div className='col-sm-4'><p><FontAwesomeIcon className='blue-item' icon={faBath} /> Baños: {cleanDataDetail[0].bathrooms}</p></div>
                                    <div className='col-sm-4'><p><FontAwesomeIcon className='blue-item' icon={faCar} /> Parqueaderos: {cleanDataDetail[0].parking}</p></div>
                                    <div className='col-sm-4'><p><FontAwesomeIcon className='blue-item' icon={faRestroom} /> Cuarto útil: {cleanDataDetail[0].util_room}</p></div>
                                    <div className='col-sm-4'><p><FontAwesomeIcon className='blue-item' icon={faNetworkWired} /> Estrato: {cleanDataDetail[0].stratum}</p></div>
                                    <div className='col-sm-4'><p><FontAwesomeIcon className='blue-item' icon={faStreetView} /> Vista: {cleanDataDetail[0].view}</p></div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                {cleanDataDetail[0].comfort === true ?
                                    <div>
                                        <h2 className="tm-text-primary tm-section-title-alt mb-4">Comodidades</h2>
                                        <div className="row">
                                            <div className='col-sm-6'><p><FontAwesomeIcon className='blue-item' icon={faWater} /> Piscina: {cleanDataDetail[0].pool === true ? 'Si' : 'No'}</p></div>
                                            <div className='col-sm-6'><p><FontAwesomeIcon className='blue-item' icon={faStop} /> Portería 24/h: {cleanDataDetail[0].goal === true ? 'Si' : 'No'}</p></div>
                                            <div className='col-sm-6'><p><FontAwesomeIcon className='blue-item' icon={faTree} /> Áreas verdes: {cleanDataDetail[0].green_area === true ? 'Si' : 'No'}</p></div>
                                            <div className='col-sm-6'><p><FontAwesomeIcon className='blue-item' icon={faSmile} /> Área de niños: {cleanDataDetail[0].children_area === true ? 'Si' : 'No'}</p></div>
                                            <div className='col-sm-6'><p><FontAwesomeIcon className='blue-item' icon={faUtensils} /> Salón comunal: {cleanDataDetail[0].communal_living === true ? 'Si' : 'No'}</p></div>
                                            <div className='col-sm-6'><p><FontAwesomeIcon className='blue-item' icon={faDumbbell} /> Gimnacio: {cleanDataDetail[0].gym === true ? 'Si' : 'No'}</p></div>
                                            <div className='col-sm-6'><p><FontAwesomeIcon className='blue-item' icon={faFutbol} /> Cancha: {cleanDataDetail[0].field === true ? 'Si' : 'No'}</p></div>
                                        </div>
                                    </div>
                                    : <></>}
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="sec-item-price">
                        <div className="row">
                            <div className="col-sm-6">
                                <h2 className="tm-text-primary tm-section-title-alt mb-4">Precio</h2>

                                <div className="row">
                                    <div className='col-sm-6'><p>Precio comercial <br /> <strong> $<PriceFormatter value={cleanDataDetail[0].price} /></strong></p> </div>

                                    <div className='col-sm-6'><p>Descuento <br /> <strong>%<PriceFormatter value={cleanDataDetail[0].discount}/></strong></p> </div>

                                    <div className='col-sm-6'><p>Ahorro total <br /> <strong>$<PriceFormatter value={((cleanDataDetail[0].discount / 100) * cleanDataDetail[0].price)}/></strong></p> </div>

                                    <div className="col-sm-6"> <p>Precio final <br /> <strong className='final-price blue-item'>$<PriceFormatter value={cleanDataDetail[0].price - ((cleanDataDetail[0].discount / 100) * cleanDataDetail[0].price)}/></strong></p></div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div>
                                    <h2 className="tm-text-primary tm-section-title-alt mb-4">Gastos mensuales</h2>
                                    <div className="row">
                                        <div className='col-sm-6'> <p>Impuesto predial <br /> <strong>$<PriceFormatter value={cleanDataDetail[0].predial}/></strong>/mes </p></div>
                                        <div className='col-sm-6'><p>Administración <br /> <strong>$<PriceFormatter value={cleanDataDetail[0].administration}/></strong></p></div>
                                        <div className='col-sm-6'><p>Servicios Públicos <br /> <strong>$<PriceFormatter value={cleanDataDetail[0].services}/></strong></p></div>
                                        <div className="col-sm-6">Promedio mensual: <strong className='final-price blue-item'>$<PriceFormatter value={cleanDataDetail[0].predial + cleanDataDetail[0].administration + cleanDataDetail[0].services}/></strong> </div>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <div className="sec-description">
                        <h2 className="tm-text-primary tm-section-title-alt mb-4">Descripción</h2>
                        <div>{cleanDataDetail[0].description}</div>
                    </div>
                    <br /><br /><br />
                </>
            ) : (
                'Cargando...'
            )}
        </div>
        <FooterElement />
        </div>
    )
}

export default Item;
