import axios from 'axios';

const UpdateService = async (endpoint, data) => {
    try {
      const url = endpoint;
      const res = await axios.put(url, data, {
        headers: { 'Content-Type': 'application/json' },
      });
      return res.data.body;
  
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  
export default UpdateService;