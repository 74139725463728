import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
// import './styles.css'

exportingInit(Highcharts);
ExportData(Highcharts);

const ColumnsChart = ({ title, datas, labels } ) => {
    const options = {
      lang: {
        downloadCSV:"Descarga CSV",       
        viewFullscreen:"Ver en pantalla completa",
        printChart: "Imprimir Gráfica",
        downloadPNG:"Descarga PNG",
        downloadJPEG:"Descarga JPEG",
        downloadPDF:"Descarga Documento PDF",
        downloadSVG:"Descarga vector SVG",
        downloadXLS:"Descarga XLS",
        viewData:"Ver datos de tabla",
        category: 'Categoría'

    },
      chart: {
        type: 'column',
      },
      title: {
        text: title,
      },
      xAxis: {
        categories: labels
      },
      yAxis: {
        min: 0,
        title: {
          text: "Valores",
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        valueDecimals: 2,
      },
      series: [
        {
          name: 'Population',
          colors: [  '#4db6ac', '#544fc5', '#009688', '#26a69a', '#03a9f4',
          '#9c27b0', '#607d8b', '#2196f3', '#00bcd4', '#0d47a1',
          '#1976d2', '#1565c0', '#0288d1', '#039be5', '#00acc1',
          '#42a5f5', '#1e88e5', '#64b5f6', '#29b6f6', '#03a9f4'],
          colorByPoint: true,
          groupPadding: 0,
          data: datas,
          dataLabels: {
            enabled: true,
            align: 'right',
            format: '{point.y:.1f}', 
            y: 10,
          },
        },
      ],
      exporting: {
        csv: {
          itemDelimiter: ',',
          decimalPoint: '.',
          dateFormat: '%Y-%m-%d %H:%M:%S',
          itemDecimals: 2,
          // Agregar el sufijo deseado aquí
          itemSuffix: '$'
        },
        // También puedes configurar el mismo sufijo para el copiado en el portapapeles
        // O simplemente omitir esta opción si deseas que sea el mismo que en CSV
        clipboard: {
          itemSuffix: '$'
        },
        event: {
          exportData: function (event) {
            const data = event.dataRows;
            for (let i = 0; i < data.length; i++) {
              if (data[i][0] === 'category') {
                data[i][0] = 'categoría';
              }
            }
            event.preventDefault();
            event.dataRows = data;
            this.viewData();
          }
        }
      },
    };


  useEffect(() => {
    const chart = Highcharts.chart('columnsChartContainer', options);
    return () => {
      chart.destroy();
    };
  }, [datas, labels, title]);

  return <div id="columnsChartContainer" style={{ height: '400px', maxWidth: '100%' }}></div>;
};

export default ColumnsChart;
