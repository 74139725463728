import '../../components/css/tooplate-infinite-loop.css'

const Tools = () => {

    return (
        <div>
            <p>Herramientas</p>
        </div>
    )
}

export default Tools;