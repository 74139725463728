import axios from 'axios';
import config from '../config';
const domain = config.api_domain.domain

const PostService = async (path, data) => {
    try {
        const url = (`${domain}/${path}`)
        const res = await axios.post(url, data, {
            headers: { 'Content-Type': 'application/json' }})
        return (res.data.body)
        
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default PostService