import React from 'react';

const PriceFormatter = ({ value }) => {
  // Verificamos si el valor es numérico
  if (typeof value !== 'number') {
    return 'Valor no válido';
  }

  // Formateamos el valor con puntos de miles y sin decimales
  const formattedValue = value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return <span>{formattedValue}</span>;
};

export default PriceFormatter;