import '../../components/css/tooplate-infinite-loop.css'
import { useState, useEffect } from 'react';
import LoaderItem from '../../services/LoaderItem';
import config from '../../config';
const domain = config.api_domain.domain

const Comunications = () => {
    const url = `${domain}/house/list/comunications`;
    const [cleanDataDetail, setCleanDataDetail] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const item = await LoaderItem(url);
          setCleanDataDetail(item);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
          setCleanDataDetail([]);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [url]);
  
    if (loading) {
      return <div>Cargando...</div>;
    }
  
    if (!cleanDataDetail || cleanDataDetail.length === 0 || cleanDataDetail === ![]) {
      // Cuando no hay elementos disponibles
      return <div>Sin elementos disponibles</div>;
    }

    return (
        <div className='p-3 container'>
            <h2 className="tm-text-primary tm-section-title mb-4">Comunicaciones</h2>
            <br />
            <table className="table table-striped">
                <thead>
                    <th scope="col">Nombre</th>
                    <th scope="col">Email</th>
                    <th scope="col">Cel</th>
                    <th scope="col">Mensaje</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">TyC</th>
                </thead>
                {cleanDataDetail.map((item, index) => (
                    <tbody className='' key={index}>
                        <td>{item.name} {item.last_name}</td>
                        <td>{item.email}</td>
                        <td>{item.phone}</td>
                        <td>{item.message}</td>
                        <td>{item.date_time}</td>
                        <td>{item.terms === true ? 'Si' : 'No' }</td>
                    </tbody>
                ))}
            </table>
        </div>
    )
}

export default Comunications;