import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import '../../components/css/tooplate-infinite-loop.css'
import UpdateService from '../../services/UpdateService';
import LoaderItem from '../../services/LoaderItem';
import config from '../../config';

const Update = () => {
    const params = useParams()
    const domain = config.api_domain.domain;
    const id = params.id
    const urlGet = `${domain}/house/houses/${id}`
    const urlUpdate = `${domain}/house/update/${id}`

    const [datas, setDatas] = useState([])
    const [formData, setFormData] = useState({});
    const [showForm, setShowForm] = useState(true);
    const [showUpdatedText, setShowUpdatedText] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const item = await LoaderItem(urlGet);
                setDatas(item);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, [id, urlGet, setDatas]);


    useEffect(() => {
        if (datas.length > 0) {
            setFormData({
                id: datas[0].id,
                title: datas[0].title,
                description: datas[0].description,
                mt2: datas[0].mt2,
                rooms: datas[0].rooms,
                bathrooms: datas[0].bathrooms,
                price: datas[0].price,
                type: datas[0].type,
                item_class: datas[0].item_class,
                city: datas[0].city,
                sector: datas[0].sector,
                parking: datas[0].parking,
                util_room: datas[0].util_room,
                discount: datas[0].discount,
                short_desc: datas[0].short_desc,
                predial: datas[0].predial,
                administration: datas[0].administration,
                services: datas[0].services,
                stratum: datas[0].stratum,
                view: datas[0].view,
                comfort: datas[0].comfort,
                pool: datas[0].pool,
                goal: datas[0].goal,
                green_area: datas[0].green_area,
                children_area: datas[0].children_area,
                communal_living: datas[0].communal_living,
                gym: datas[0].gym,
                field: datas[0].field,
                maps: datas[0].maps,
                floor: datas[0].floor,
                state: datas[0].state,
                year: datas[0].year,
                status_work: datas[0].status_work
            });
        }
    }, [datas]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangeCheck = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData({ ...formData, [e.target.name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        UpdateService(urlUpdate, formData)
            .then(() => {
                setShowForm(false);
                setShowUpdatedText(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenForm = () => {
        setShowForm(true);
        setShowUpdatedText(false);
    };


    return (
<div className='container'>
            {showForm ?
                <div className="form-floating mb-3">
                    <div className="row">
                        <h2>Generalidades</h2>
                        <div className="col-sm-6">
                            <label htmlFor="title">Titulo</label>
                            <input id="title" name="title" type="text" placeholder="Título del inmueble" className="form-control" value={formData.title} onChange={handleChange} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="mt2">Mt2</label>
                            <input id="mt2" name="mt2" type="number" placeholder="Metros cuadrados del inmueble" className="form-control" value={formData.mt2} onChange={handleChange} />
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="rooms">Habitaciones</label>
                            <input id="rooms" name="rooms" type="number" placeholder="Número de habitaciones" className="form-control" value={formData.rooms} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="bathrooms">Baños</label>
                            <input id="bathrooms" name="bathrooms" type="number" placeholder="Número de baños" className="form-control" value={formData.bathrooms} onChange={handleChange}/>
                        <br />
                        </div>

                        <div className="col-sm-6">
                            <label htmlFor="type">Tipo de inmueble</label>
                            <input id="type" name="type" type="text" placeholder="Tipo" className="form-control" value={formData.type} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="item_class">Clase de inmueble</label>
                            <input id="item_class" name="item_class" type="text" placeholder="Clase de inmueble" className="form-control" value={formData.item_class} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="city">Ciudad</label>
                            <input id="city" name="city" type="text" placeholder="Ciudad" className="form-control" value={formData.city} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="sector">Sector</label>
                            <input id="sector" name="sector" type="text" placeholder="Sector" className="form-control" value={formData.sector} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="parking">Número de parkings</label>
                            <input id="parking" name="parking" type="number" placeholder="Cantidad de parqueaderos" className="form-control" value={formData.parking} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="util_room">Número de cuartos útiles</label>
                            <input id="util_room" name="util_room" type="number" placeholder="Número de cuartos útiles" className="form-control" value={formData.util_room} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="short_desc">Descripción corta</label>
                            <input id="short_desc" name="short_desc" type="text" placeholder="Descripción corta" className="form-control" value={formData.short_desc} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-12">
                            <label htmlFor="description">Descripción larga</label>
                            <textarea rows={4} id="description" name="description" type="textarea" placeholder="Descripción del inmueble" className="form-control"  value={formData.description} onChange={handleChange}/>
                        <br />
                        </div>
                        <br /><br /><br />
                        <h2>Aspectos economicos</h2>
                        <div className="col-sm-6">
                            <label htmlFor="price">Precio</label>
                            <input id="price" name="price" type="number" placeholder="Precio" className="form-control" value={formData.price} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="discount">Descuento</label>
                            <input id="discount" name="discount" type="number" placeholder="Descuento" className="form-control" value={formData.discount} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="predial">Predial mensual</label>
                            <input id="predial" name="predial" type="number" placeholder="Predial" className="form-control" value={formData.predial} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="administration">Administración</label>
                            <input id="administration" name="administration" type="number" placeholder="Administración" className="form-control" value={formData.administration} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="services">Servicios</label>
                            <input id="services" name="services" type="number" placeholder="Servicios públicos" className="form-control" value={formData.services} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="stratum">Estrato</label>
                            <input id="stratum" name="stratum" type="number" placeholder="Estrato" className="form-control" value={formData.stratum} onChange={handleChange}/>
                        <br />
                        </div>
                        <br /><br /><br />
                        <h2>Otros</h2>
                        <div className="col-sm-6">
                            <label htmlFor="view">Vista del inmueble</label>
                            <input id="view" name="view" type="text" placeholder="Vista" className="form-control" value={formData.view} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="maps">Google Maps</label>
                            <input id="maps" name="maps" type="text" placeholder="Mapas" className="form-control" value={formData.maps} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="floor">Piso</label>
                            <input id="floor" name="floor" type="number" placeholder="Piso" className="form-control" value={formData.floor} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="year">Año de construcción</label>
                            <input id="year" name="year" type="number" placeholder="Año de construcción" className="form-control" value={formData.year} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="status_work">Estado de la obra</label>
                            <input id="status_work" name="status_work" type="text" placeholder="Estado de la obra" className="form-control" value={formData.status_work} onChange={handleChange}/>
                        <br />
                        </div>
                        <div className="col-sm-4">
                            <br />
                            <a href={`../images/${formData.id}`}><button className='btn btn-secondary'>Imagenes del inmueble</button></a>
                            <br />
                        <br />
                        </div>
                        <br /><br /><br />
                        <h2>Comodidades</h2>
                        <br />
                        <br />
                        <div className="form-check col-sm-4">
                            <input name='comfort' type="checkbox" className="form-check-input" checked={formData.comfort} id="comfort" onChange={handleChangeCheck}/>
                            <label className="form-check-label" htmlFor="comfort">
                                Comodidades
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input name='pool' type="checkbox" className="form-check-input" checked={formData.pool} id="pool" onChange={handleChangeCheck} />
                            <label className="form-check-label" htmlFor="pool">
                                Piscina
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input name='goal' type="checkbox" className="form-check-input" checked={formData.goal} id="goal" onChange={handleChangeCheck} />
                            <label className="form-check-label" htmlFor="goal">
                                Portería
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input name='green_area' type="checkbox" className="form-check-input" checked={formData.green_area} id="greenArea" onChange={handleChangeCheck} />
                            <label className="form-check-label" htmlFor="greenArea">
                                Zona verde
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input name='children_area' type="checkbox" className="form-check-input" checked={formData.children_area} id="childrenArea" onChange={handleChangeCheck} />
                            <label className="form-check-label" htmlFor="childrenArea">
                                Zona de niños
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input name='communal_living' type="checkbox" className="form-check-input" checked={formData.communal_living} id="communalLiving" onChange={handleChangeCheck} />
                            <label className="form-check-label" htmlFor="communalLiving">
                                Salón comunal
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input name='gym' type="checkbox" className="form-check-input" checked={formData.gym} id="gym" onChange={handleChangeCheck} />
                            <label className="form-check-label" htmlFor="gym">
                                Gimnasio
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input name='field' type="checkbox" className="form-check-input" checked={formData.field} id="field" onChange={handleChangeCheck} />
                            <label className="form-check-label" htmlFor="field">
                                Cancha
                            </label>
                            <br /><br />
                        <br />
                        </div>
                        <div className="form-check col-sm-4">
                            <input name='state' type="checkbox" className="form-check-input" checked={formData.state} id="state" onChange={handleChangeCheck} />
                            <label className="form-check-label" htmlFor="state">
                                Estado
                            </label>
                            <br /><br />
                        <br />
                        </div>
                    <br />
                    </div>
                    <button className='btn btn-primary' onClick={handleSubmit}>Enviar</button>
                <br />
                </div>
                : (
                    <>
                        <p>Parámetros actualizados</p>
                        <button className='btn btn-primary' onClick={handleOpenForm}>Editar Parámetros</button>
                    </>
                )}
        <br />

        </div>
    )
}

export default Update;