 import React from 'react'
 
const Homebar = () => {
   return (
    <nav className="navbar navbar-expand-md tm-navbar" style={{backgroundColor: '#276285'}} id="tmNav">
    <div className="container">
        <div className="tm-next">
            <a href="/" className="navbar-brand">Remateseninmuebles.com</a>
        </div>
    </div>
    </nav>
   )
 }
 
 export default Homebar